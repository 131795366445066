table {
  table-layout: fixed;
  border-collapse: collapse;
}

.weekly-view td.date div {
  display: flex;
  flex-direction: column;
}

th, td {
  border: 1px solid black;
  text-align: center;
  width: 45px;
  height: 45px;
}

tbody td {
  cursor: pointer;
}

.mood-awesome {
  background-color: #701dd5;
  color: #fff;
}

.mood-good {
  background-color: #5de034;
}

.mood-meh {
  background-color: #4080c4;
}

.mood-bad {
  background-color: #1d1123;
  color: #fff;
}

.mood-terrible {
  background-color: #a92f0e; /* Red */
}

/* Additional styling for the select element */
select {
  padding: 3px;
  border-radius: 5px;
}
